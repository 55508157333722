import { io } from "socket.io-client";
import { SOCKET_BASE_URL } from "./config";



const socket = io(SOCKET_BASE_URL, {
    transports: ['websocket'],
    autoConnect: false
})


class SocketIo {
    connectToSocket = (api_token, callback = () => { }, success = () => { }) => {
        socket.io.opts.query = {
            authorization: api_token
        }
        socket.connect();
        socket.on('connect', () => {
            console.log('Socket Connected')
            success()
        })

        socket.on('connect_error', function (err) {
            console.log('Socket Connection Error')
            callback()
        });
    }
    emit = (eventName, params = {}, onSuccess) => {
        socket.emit(eventName, params, res => {
            onSuccess(res.data);
        })

    }

    on = (eventName, callback) => {
        socket.on(eventName, callback)
    }


    dispose = (eventName) => {
        socket.off(eventName);
    }

    disconnect = () => {
        socket.disconnect();
    }

    getStatus = () => {
        return socket.connected
    }
}

export default new SocketIo();
import { createSlice } from "@reduxjs/toolkit";
import { settingsApi } from "../services/settingsApi";

const initialState = {
  pages: [],
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        settingsApi.endpoints.getAllStaticContent.matchFulfilled,
        (state, { payload }) => {
          state.pages = payload.data;
        }
      )
      .addMatcher(
        settingsApi.endpoints.getAllStaticContent.matchRejected,
        (state) => {
          state.pages = null;
        }
      );
  },
});

export const { setUserInfo, unsetUserInfo, updateUsers } =
  settingsSlice.actions;

export default settingsSlice.reducer;

import _ from "lodash";
import React, { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import CountUp from "react-countup";

const WithDrawalAmount = ({ amount, styles, onClick }) => {
  useEffect(() => {    
    // console.log(!_.isEmpty(amount));
  }, [amount])
  // console.log(amount);
  
  return (
    <div
      className={`d-flex flex-column p-3 rounded-4 bg-light border align-items-center ${styles.withdrawCard}`}
      onClick={onClick}
    >
      <div
        className={` d-flex justify-content-evenly w-100 align-items-center`}
      >
        <img
          alt="withdraw"
          src="../images/withdrawal.png"
          width={50}
          height={50}
        />
        {!_.isNumber(amount) ? (
          <Spinner />
        ) : (
          <span className={`fs-1 fw-bold`}>
            $ <CountUp end={amount} />
          </span>
        )}
      </div>

      <spam className={`mt-2 gray-text text-center`}>Withdrawal Amount</spam>
    </div>
  );
};

export default WithDrawalAmount;

import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import { useCreateOrderShipmentMutation } from "../../Data/services/orderApi";
import Toast, { ErrorToast } from "../../Hooks/useToast";
import { Button, Input } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/viewOrderModal.module.scss";
import { useCashOutMutation } from "../../Data/services/paymentApi";

const WithDrawModal = ({ handleEditModalClose, rowData, refetch, currentBalance }) => {
  const [rowId, setRowId] = useState("");
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {},
  });
  // const [selectedRole, setSelectedRole] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedServiceType, setSelectedServiceType] = useState("");
  const [selectedPackageType, setSelectedPackageType] = useState("");


  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  // MARK: CREATE SHIPMENT

  const [cashOut, { isLoading }] = useCashOutMutation();

  const handleShipment = async (data) => {
    
    console.log(data.amount);
    console.log(currentBalance);

    try {
      const response = await cashOut(data);
      console.log(response);

      if (!response.data && response.error.status === 500) {
        ErrorToast(response.error.data?.message);
      }

      if (response.data.success) {
        refetch();
        setTimeout(() => {
          Toast(response.data.message, "success");
          handleEditModalClose();
        }, 2000);
      }
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      } else {
        ErrorToast(error.data?.message);
      }
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">
              Withdraw your amount
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <Form onSubmit={handleSubmit(handleShipment)} className="w-100">
            <div className="w-100">
              <Row className="w-100">
                <Col lg={12}>
                  <Row>
                    <Col md={12}>
                      <p className="m-0 gray-text">
                        Amount
                        {errors?.amount?.message ? (
                          <span className="ms-2 text-danger text-center">
                            {errors?.amount?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <Controller
                        control={control}
                        name="amount"
                        rules={{
                          required: "required.",
                          validate: (value) =>
                            value <= currentBalance || `Amount cannot exceed ${currentBalance}`,
                        }}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Input
                            classes="mt-2 mb-3"
                            type="number"
                            // max={currentBalance}
                            min={1}
                            placeholder="Enter amount"
                            value={value}
                            onChange={onChange}
                          />
                        )}
                      />
                    </Col>
                  </Row>
              
                  
                  <Row>
                    <Col md={12}>
                      <Button
                        classes={`${cssStyles.cardBtn} mt-4 `}
                        // text={
                        //   isLoading || updateLoading ? (
                        //     <BeatLoader size={10} />
                        //   ) : data ? (
                        //     "Update"
                        //   ) : (
                        //     "Save"
                        //   )
                        // }
                        text={
                          isLoading ? (
                            <BeatLoader size={10} />
                          ) : (
                            "Withdraw"
                          )
                        }
                        // onClick={handleSubmit}
                        type="submit"
                        disabled={isLoading}
                      ></Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
      </ModalContainer>
    </div>
  );
};

export default WithDrawModal;

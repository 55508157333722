import React from "react";
import { Spinner } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";

const ConnectAccount = ({ amount, styles, onClick, loading, merchantId }) => {
  return (
    <div
      className={`d-flex flex-column p-3 rounded-4 bg-light border align-items-center ${styles.withdrawCard}`}
      onClick={onClick}
    >
      <span className={``}>
        {loading ? <Spinner /> : 
        
        <FaCheckCircle size={40} color= {merchantId ? "green" : "grey"} />
        }
      </span>
      {merchantId ? (
        <spam className={`mt-2 text-center`}>Account Connected </spam>
      ) : (
        <>
          <spam className={`mt-2 text-center`}>Not connected any account </spam>

          <span className={`gray-text text-center`}>click to connect</span>
        </>
      )}
    </div>
  );
};

export default ConnectAccount;

import { createSlice } from "@reduxjs/toolkit";
import { alertsApi } from "../services/alertsApi";

const initialState = {
  alerts: [],
};

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        alertsApi.endpoints.getAllAlerts.matchFulfilled,
        (state, { payload }) => {
          state.alerts = payload.data;
        }
      )
      .addMatcher(alertsApi.endpoints.getAllAlerts.matchRejected, (state) => {
        state.alerts = null;
      });
  },
});

export const { setUserInfo, unsetUserInfo, updateUsers } = alertsSlice.actions;

export default alertsSlice.reducer;

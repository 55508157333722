import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import BeatLoader from "react-spinners/BeatLoader";
import {
  useAddFestiveCategoryMutation,
  useUpdateFestiveCategoryMutation,
} from "../../Data/services/festiveCategoryApi";
import Toast, { ErrorToast } from "../../Hooks/useToast";
import { Button, Input } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editCategoryModal.module.scss";

const EditFestiveCategoryModal = ({
  handleEditModalClose,
  data,
  refetch,
  isCreating,
}) => {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [rowId, setRowId] = useState("");

  const [selectedStatus, setSelectedStatus] = useState("");

  const statusList = [
    {
      value: "seasonal",
      label: "Seasonal",
    },
    {
      value: "gift-card",
      label: "Gift Card",
    },
  ];

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      // console.log(data);
      setRowId(data.id);
      setValue("type", data.type);
      setValue("category", data.category);
      setSelectedStatus(data.type);
    }
  }, [data, mounted]);

  // MARK: ADD CATEGORY
  const [addCategory, { isError, isLoading, isSuccess }] =
    useAddFestiveCategoryMutation();

  const handleAddCategory = async (data) => {
    console.log("handleAddCategory");
    console.log(data);
    // console.log(data.image[0], "data.image");
    // return;

    if (isSubmitted) return;

    try {
      setIsSubmitted(true);
      // const formData = new FormData();
      // formData.append("type", data.type);
      // formData.append("category", data.category);

      // if (data.image !== undefined) {
      //   formData.append("image", data.image[0]);
      // }

      // Log the FormData entries to see what is being sent
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}:`, value);
      // }

      const response = await addCategory(data);
      console.log(response);

      if (response.data.success) {
        refetch();
        handleEditModalClose();
        Toast(response.data.message, "success");
      }

      setIsSubmitted(false);
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  // MARK: UPDATE CATEGORY
  // useUpdateCategoryMutation
  const [
    updateCategory,
    {
      isError: updateError,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
    },
  ] = useUpdateFestiveCategoryMutation();

  const handleUpdateCategory = async (data) => {
    console.log("handleUpdateCategory");
    console.log(data);
    console.log(rowId);
    // console.log(data.image[0], "data.image");
    if (isSubmitted) return;
    try {
      setIsSubmitted(true);
      // const formData = new FormData();
      // formData.append("name", data.name);

      // if (data.image !== undefined) {
      //   formData.append("image", data.image[0]);
      // }

      // Log the FormData entries to see what is being sent
      // for (let [key, value] of formData.entries()) {
      //   console.log(`${key}:`, value);
      // }

      const id = rowId;
      const response = await updateCategory({ formData: data, id });
      console.log(response);

      if (response.data.success) {
        refetch();
        Toast(response.data.message, "success");
        handleEditModalClose();
      }

      setIsSubmitted(false);
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">
              {data ? "Edit Festive Category" : "Add Festive Category"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className="w-100">
            <Form
              onSubmit={handleSubmit(
                data ? handleUpdateCategory : handleAddCategory
              )}
            >
              <Row>
                <Col md={12}>
                  <p className="m-0">Category Name</p>
                  <Controller
                    control={control}
                    name="category"
                    // defaultValue={""}
                    rules={{
                      required: "Category Name is required.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Input
                        classes="mt-2 mb-3"
                        type="text"
                        placeholder="Enter category name"
                        value={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  {errors?.category?.message ? (
                    <p className="m-0 text-danger text-center">
                      {errors?.category?.message}
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <p className="m-0">Sub-category of</p>
                  <Controller
                    control={control}
                    name="type"
                    // defaultValue={""}
                    rules={{
                      required: "Select type.",
                    }}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <SelectFieldComponent
                        firstOption={
                          _.isEmpty(selectedStatus)
                            ? "Select Type"
                            : selectedStatus
                        }
                        optionsList={statusList}
                        value={value}
                        // onSelectChange={handleStatusChange}
                        onSelectChange={onChange}
                      />
                    )}
                  />
                  {errors?.type?.message ? (
                    <p className="m-0 text-danger text-center">
                      {errors?.type?.message}
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Button
                    classes={`${cssStyles.cardBtn} mt-4 `}
                    text={
                      isSubmitted ? (
                        <BeatLoader size={10} />
                      ) : data ? (
                        "Update"
                      ) : (
                        "Save"
                      )
                    }
                    // onClick={handleSubmit}
                    type="submit"
                    disabled={isSubmitted}
                  ></Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditFestiveCategoryModal;

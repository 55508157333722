import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

/**Import Assets */
import styles from "./styles/ProtectedLayout.module.scss";
// import cssStyles from './styles/notification.module.css';
import { BiMoneyWithdraw } from "react-icons/bi";
/**Import Custom Component */
import { WEB_ROUTES } from "../../constants";
import { baseRoute } from "../../Helper";
import Navbar from "./Navbar";

// Icons
import { CiBarcode, CiChat2 } from "react-icons/ci";
import { FaBlogger, FaChartArea, FaUser } from "react-icons/fa";
import { FaProductHunt, FaSackDollar } from "react-icons/fa6";
import { IoIosAlert, IoIosSettings } from "react-icons/io";
import {
  MdCategory,
  MdDashboard,
  MdOutlineCategory,
  MdOutlineProductionQuantityLimits,
} from "react-icons/md";
import { TbBrandProducthunt, TbLogout2 } from "react-icons/tb";
import { useGetDashboardDataQuery } from "../../Data/services/dashboardApi";
import { useGetAllFestiveCategoriesQuery } from "../../Data/services/festiveCategoryApi";
import { removeToken } from "../../Data/services/localStorageService";
import { useGetAllProductsQuery } from "../../Data/services/productApi";
import Toast from "../../Hooks/useToast";
import LogoutModal from "../Modals/LogoutModal";

export default function ProtectedLayout({
  isSearchBar,
  isSettingButton,
  selectedRoute,
  children,
}) {
  const [collapsed, setCollapsed] = useState(window.innerWidth > 991);

  // inject data
  const { data } = useGetDashboardDataQuery({
    refetchOnMountOrArgChange: true,
  });
  // inject data
  const { data: ProductData } = useGetAllProductsQuery();
  const { data: FestiveCategoryData } = useGetAllFestiveCategoriesQuery();

  // console.log(ProductData);
  // console.log(FestiveCategoryData);

  const [logoutModal, setLogoutModal] = useState(false);

  const navigate = useNavigate();
  // todo: menu
  const items = [
    {
      label: "Dashboard",
      icon: <MdDashboard />,
    },
    {
      label: "User Management",
      icon: <FaUser />,
    },
    {
      label: "Category Management",
      icon: <MdCategory />,
    },
    {
      label: "Sub Category Management",
      icon: <MdCategory />,
    },
    {
      label: "Product Management",
      icon: <FaProductHunt />,
    },
    {
      label: "Order Management",
      icon: <MdOutlineProductionQuantityLimits />,
    },
    {
      label: "Promos Management",
      icon: <FaChartArea />,
    },
    {
      label: "Blogs Management",
      icon: <FaBlogger />,
    },
    {
      label: "Alert Management",
      icon: <IoIosAlert />,
    },
    {
      label: "Promo Code Management",
      icon: <CiBarcode />,
    },
    {
      label: "Festive Category",
      icon: <MdOutlineCategory />,
    },
    {
      label: "Festive Products",
      icon: <TbBrandProducthunt />,
    },
    // {
    //   label: "Send Notification",
    //   icon: <IoNotifications />,
    // },
    {
      label: "Membership View",
      icon: <FaSackDollar />,
    },

    {
      label: "Withdrawal Management",
      icon: <BiMoneyWithdraw />,
    },
    {
      label: "Support",
      icon: <CiChat2 />,
    },
    {
      label: "Settings",
      icon: <IoIosSettings />,
    },
  ];

  const handleToggle = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuClose = () => {
    setCollapsed(false);
  };

  const handleViewProfile = () => {
    navigate(baseRoute(WEB_ROUTES.PROFILE));
  };

  const handleLogout = () => {
    try {
      removeToken();
      navigate("/account");
      window.location.reload();
      Toast("Logged Out Successfully", "success");
    } catch {
      Toast("An error occurred during log out", "error");
    }
  };

  const handleLogoutModalOpen = () => {
    setLogoutModal(true);
  };

  const handleLogoutModalClose = () => {
    setLogoutModal(false);
  };

  return (
    <div className={styles.ProtectedLayout}>
      <div
        className={collapsed ? styles.background : ""}
        onClick={handleMenuClose}
      ></div>
      <div
        className={`${styles.menu} ${collapsed ? `${styles.collapsed} visible` : styles.notCollapsed
          }`}
        id="sider"
      >
        <div
          className={`${styles.logo} ${!collapsed ? styles.notCollapsed : ""}`}
          to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}
        >
          <img
            src={collapsed ? "/images/logo_name.png" : "/images/small_logo.png"} //todo:
            alt="Logo"
            className={
              collapsed ? styles.imageCollapsed : styles.imageNotCollapsed
            }
            width={"100%"}
            height={"100%"}
          />
        </div>

        {/* <div className={`${styles.drawer} ${collapsed ? styles.collapsed : ''}`}>

                    <span onClick={handleToggle}>
                        <BiLogOut />
                    </span>
                </div> */}

        {items.map(({ icon, label }, index) => {
          return (
            <>
              {label === "Promos Management" ||
                label === "Promo Code Management" ||
                label === "Membership View" ||
                label === "Support" ? (
                <div className={`${styles.divider}`}></div>
              ) : null}
              <Link
                to={`${process.env.REACT_APP_DOMAIN_DIR}/${label
                  .toLowerCase()
                  .replace(/[^A-Z0-9]/gi, "-")}`}
                className={`${styles.item} ${index === selectedRoute ? styles.active : ""
                  }`}
                key={index}
              >
                <span style={{ fontSize: "20px", display: "flex" }}>
                  {icon}
                </span>
                {collapsed ? <p>{label}</p> : ""}
              </Link>
            </>
          );
        })}
        <Link
          className={styles.item}
          // onClick={handleLogout}
          onClick={handleLogoutModalOpen}
        // onClick={() =>
        //   submit("Confirm Logout", "Are you sure you want to logout?", () =>
        //     handleLogout()
        //   )
        // }
        >
          {/* <img src={logout} alt="Icon1" width={20} height={20} /> */}
          <span style={{ fontSize: "20px", display: "flex" }}>
            <TbLogout2 />
          </span>
          {collapsed ? <p>Logout</p> : ""}
        </Link>
      </div>
      <div className={styles.routeContent}>
        {logoutModal ? (
          <LogoutModal
            handleLogoutModalClose={handleLogoutModalClose}
            data={""}
            onLogout={handleLogout}
          />
        ) : null}

        <Navbar
          isSearchBar={isSearchBar}
          isSettingButton={isSettingButton}
          handleToggle={handleToggle}
        />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}

import { baseApi } from "./baseApi";
import { getToken } from "./localStorageService";

export const dashboardApi = baseApi.injectEndpoints({
  credentials: "include",
  endpoints: (builder) => ({
    getDashboardData: builder.query({
      query: () => {
        return {
          url: "dashboard-data",
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            authorization: `Bearer ${getToken()}`,
          },
        };
      },
      keepUnusedDataFor: 0,
      refetchOnFocus: true,
    }),
  }),
});

export const { useGetDashboardDataQuery } = dashboardApi;

import React from "react";
import styles from "./index.module.scss";

import { Form } from "react-bootstrap";
import { IoIosArrowBack } from "react-icons/io";

export const ShadowContainer = () => {
  return <div className={styles.ShadowContainer}></div>;
};

export const BackButton = ({
  isRelative = false,
  handleBackButton = () => {},
}) => {
  return (
    <div
      className={`${styles.BackButton} ${isRelative ? styles.relative : ""}`}
      onClick={handleBackButton}
    >
      <IoIosArrowBack />
    </div>
  );
};

export const Input = ({
  type = "text",
  classes = "mt-1 mb-2",
  style = {},
  size = "lg",
  placeholder = "",
  value = "",
  disable = false,
  as = "input",
  name = "",
  rows = 1,
  onChange = () => {},
}) => {
  return (
    <Form.Control
      name={name}
      size={size}
      className={`${styles.Input} ${classes}`}
      placeholder={placeholder}
      type={type}
      value={value}
      as={as}
      rows={rows}
      disable={disable.toString()}
      onChange={onChange}
    />
  );
};

///#region 066 custom input

export const CustomInput = ({
  name = "",
  placeholder = "",
  classes = "mt-1 mb-2",
  type = "",
  autoFocus = false,
  onChange,
  // value = "",
}) => {
  return (
    <Form.Control
      name={name}
      className={`${styles.Input} ${classes}`}
      placeholder={placeholder}
      autoFocus={autoFocus}
      onChange={onChange}
      // value={value}
      type={type}
    />
  );
};

export const Description = ({
  text = "",
  classes = "",
  onClick = () => {},
}) => {
  return (
    <p className={`${styles.Description} ${classes}`} onClick={onClick}>
      {text}
    </p>
  );
};

export const CardDescription = ({ text = "", classes = "" }) => {
  return <p className={`${styles.Description} ${classes} fs-10`}>{text}</p>;
};

export const Title = ({ text = "", classes = "" }) => {
  return <h1 className={`${styles.Header} ${classes}`}>{text}</h1>;
};

export const SubTitle = ({ text = "", classes = "" }) => {
  return <h2 className={`${styles.SubTitle} ${classes}`}>{text}</h2>;
};
export const CardTitle = ({ text = "", classes = "" }) => {
  return <h6 className={`${styles.CardTitle} ${classes} `}>{text}</h6>;
};
export const CardMatchTitle = ({ text = "", classes = "" }) => {
  return (
    <h6 className={`${styles.CardMatchTitle} ${classes} mt-1 mb-1`}>{text}</h6>
  );
};

export const Button = ({
  text = "",
  classes = "",
  style = {},
  onClick = () => {},
  disabled = false,
  type = "",
  className,
}) => {
  return (
    <button
      className={`${styles.Button} ${classes} ${className}`}
      style={style}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {text}
    </button>
  );
};

export const TextDivider = ({ text = "", classes = "" }) => {
  return <div className={`${styles.TextDivider} ${classes}`}>{text}</div>;
};

export const UnorderedList = ({
  lists = [],
  classes = "",
  item_classes = "",
}) => {
  return (
    <ul className={`${styles.UL} ${classes} fs-7`}>
      {lists.map((item, index) => (
        <li key={index} className={`${item_classes} mt-2 mb-2 fs-7`}>
          {item}
        </li>
      ))}
    </ul>
  );
};

export const Checbox = ({ label = "", classes = "" }) => {
  return (
    <Form.Check
      type="checkbox"
      className={`${styles.Checkbox} ${classes}`}
      label={label}
    />
  );
};

export const InputOTP = ({ props }) => {
  return (
    <Form.Control
      {...props}
      type="text"
      className={`${styles.Input} ${styles.InputOTP}`}
      size="lg"
    />
  );
};

export const LiveBadge = ({ text = "Live", classes = "" }) => {
  return <span className={`${styles.LiveBadge} ${classes}`}>{text}</span>;
};

export const Score = ({ text = "", classes = "" }) => {
  return <span className={`${styles.Score} ${classes}`}>{text}</span>;
};

/*#region: Developer 66*/
const CustomSelect = ({
  name = "",
  options = [],
  placeholder = "",
  classes = "mt-1 mb-2",
  autoFocus = false,
  onChange,
}) => {
  return (
    <Form.Select
      name={name}
      className={`${styles.Select} ${classes}`}
      aria-label={placeholder}
      autoFocus={autoFocus}
      onChange={onChange}
    >
      <option value="">{placeholder}</option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </Form.Select>
  );
};

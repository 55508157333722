import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "../services/userApi";
import { subCategoryApi } from "../services/subCategoryApi";

const initialState = {
  subCategories: [],
};

export const subCategorySlice = createSlice({
  name: "subCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        subCategoryApi.endpoints.getAllSubCategories.matchFulfilled,
        (state, { payload }) => {
          state.subCategories = payload.data;
        }
      )
      .addMatcher(
        subCategoryApi.endpoints.getAllSubCategories.matchRejected,
        (state) => {
          state.subCategories = null;
        }
      );
  },
});

export const { setUserInfo, unsetUserInfo, updateUsers } =
  subCategorySlice.actions;

export default subCategorySlice.reducer;

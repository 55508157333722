import React from 'react'

import styles from "./styles/EmptyThreadContainer.module.scss"
import { Spinner } from 'react-bootstrap'

export default function EmptyThreadContainer({ text = "No Chats Available", isLoading = false }) {
    return (
        <div className={styles.EmptyThreadContainer}>
            {isLoading ? <Spinner /> :
                <p className="m-0 fs-3">
                    {text}
                </p>
            }
        </div>
    )
}
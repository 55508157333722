import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSocket } from "../../../Context/SocketContext";
import { useSelector } from "react-redux";
import _ from "lodash";


export const ChatContext = createContext();

export function useChatContext() {
    return useContext(ChatContext)
}


export default function ChatContextProvider({ children }) {
    const socket = useSocket();
    const [search, setSearch] = useState('');
    const { isSocketConnected, user } = useSelector(state => state.auth)


    const INITIAL_CHAT_THREAD = useMemo(() => {
        return {
            isError: false,
            isLoading: true,
            data: []
        }
    }, [])



    const [chatRoom, setChatRoom] = useState({})


    const [chatThread, setChatThread] = useState(INITIAL_CHAT_THREAD)

    const allChatThreads = useMemo(() => {
        if (!search) return chatThread

        return {
            ...chatThread,
            data: chatThread?.data.filter(item => {
                const room_user = (item.sender.id === user.id) ? item.receiver : item.sender;
                return room_user.displayName?.toLowerCase().includes(search.toLowerCase())
            })
        }

    }, [chatThread, search])


    useEffect(() => {
        if (isSocketConnected) {
            socket.on('_get_chat_threads', (data) => {
                setChatThread(prev => {
                    return {
                        ...prev,
                        isLoading: false,
                        data: data?.data?.data || []
                    }
                })
            })
        }


        return () => {
            if (isSocketConnected) {
                socket.dispose('_get_chat_threads')
            }
        }

    }, [isSocketConnected])

    useEffect(() => {
        if (!isSocketConnected) return


        socket.on('_send_message', (res) => {
            const data = res?.data || {};
            setChatThread(prev => {
                return {
                    ...prev,
                    data: prev?.data.map(item => {
                        // if (item.chat_room_slug !== data?.chat_room_slug) return item
                        if (item.roomId !== data?.roomId) return item
                        return {
                            ...item,
                            chat_messages: [{
                                ...data,
                                type: data?.type,
                                message: data?.message,
                                createdAt: data?.createdAt,
                            }],

                            // ...item,
                            // message: {
                            //     ...item?.message,
                            //     message_type: data?.message_type,
                            //     message: data?.message,
                            //     createdAt: data?.message_timestamp,
                            // },
                            // unread_message_count: (chatRoom?.id === data?.id) ? item?.unread_message_count : (item?.unread_message_count + 1)
                        }
                    })
                }
            })
        })


        return () => {
            if (isSocketConnected) {
                socket.dispose('_send_message')
            }
        }

    }, [isSocketConnected, chatRoom])


    useEffect(() => {
        if (!isSocketConnected) return

        socket.emit('get_chat_threads', { userId: user.id })

    }, [isSocketConnected])




    const resetMessageCount = (chat_room_slug) => {
        setChatThread(prev => {
            return {
                ...prev,
                data: prev?.data.map(item => {
                    if (item.chat_room_slug !== chat_room_slug) return item
                    return {
                        ...item,
                        unread_message_count: 0
                    }
                })
            }
        })

    }

    const updateLastMessagePayload = (data) => {

        setChatThread(prev => {
            return {
                ...prev,
                data: prev?.data.map(item => {
                    if (item.roomId !== data?.roomId) return item
                    return {
                        ...item,
                        chat_messages: [{
                            ...data,
                            type: data?.type,
                            message: data?.message,
                            createdAt: data?.createdAt,
                        }],
                    }
                })
            }
        })

    }




    return (
        <ChatContext.Provider value={{
            search,
            setSearch,
            chatThread: allChatThreads,
            chatRoom,
            setChatRoom,
            resetMessageCount,
            updateLastMessagePayload,
        }}>
            {children}
        </ChatContext.Provider>
    )

}
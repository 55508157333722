import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import {
  Button,
  CustomInput,
  Description,
  Title,
} from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { WEB_ROUTES } from "../../constants";
import { useForgotPasswordMutation } from "../../Data/services/userAuthApi";
import Toast, { SuccessToast } from "../../Hooks/useToast";
import { ACCOUNT_ENUM } from "./Route";

export default function ForgotPassword({ setPage }) {
  const { handleSubmit, control, register } = useForm();
  const navigate = useNavigate();
  // const isLoading = true;
  // console.log(isLoading, "isLoading");
  const handleBackButtonClick = () => {
    setPage(ACCOUNT_ENUM.LOGIN);
  };

  const [forgotPassword, { isError, isLoading, isSuccess }] =
    useForgotPasswordMutation();

  const handleDataSubmit = async (userData) => {
    console.log(userData);
    try {
      // Use unwrap() to get the response data directly
      const response = await forgotPassword(userData);

      // console.log(response);

      if (!response.error && response.data.success) {
        navigate(WEB_ROUTES.ACCOUNT);
        SuccessToast(response.data.message);
      }

      if (response.error) {
        Toast(response.error.data.message, "error");
      }

      // dispatch(updateLogin(true))
    } catch (error) {
      console.log(error);
      Toast(error?.data?.message, "error");
    }
  };

  return (
    <AccountLayout
      hasBackButton={true}
      handleBackButton={handleBackButtonClick}
    >
      <Title text="Forgot Password" classes="mb-2 black-text" />
      <Description
        text="In order to reset your password you need to enter your registered email address."
        classes="text-center fs-7 black-text"
      />
      <Form onSubmit={handleSubmit(handleDataSubmit)}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                placeholder="Email"
                type="email"
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
        <Button
          text="Continue"
          classes="mt-3 mb-2"
          disabled={isLoading}
          type="submit"
        />
      </Form>
    </AccountLayout>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { festiveCategoryApi } from "../services/festiveCategoryApi";

const initialState = {
  categories: [],
};

export const festiveCategorySlice = createSlice({
  name: "festive-category",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        festiveCategoryApi.endpoints.getAllFestiveCategories.matchFulfilled,
        (state, { payload }) => {
          state.categories = payload.data;
        }
      )
      .addMatcher(
        festiveCategoryApi.endpoints.getAllFestiveCategories.matchRejected,
        (state) => {
          state.categories = null;
        }
      );
  },
});

export const { setUserInfo, unsetUserInfo, updateUsers } =
  festiveCategorySlice.actions;

export default festiveCategorySlice.reducer;

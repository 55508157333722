import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import GlobalStyles from "../../_global.scss";
import "./table.scss";

const Table = ({ data, columns, indication }) => {
  const { primaryColor, secondBgColor, yellowColor, primaryColorWithAlpha } =
    GlobalStyles;

  // PAGINATION STYLES
  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const activeStyle = {};
    if (active) {
      activeStyle.backgroundColor = yellowColor;
      activeStyle.color = "black";

      activeStyle.borderColor = primaryColor;
      activeStyle.borderSize = "5px";
      activeStyle.borderStyle = "solid";

      activeStyle.padding = "15px  30px";
      activeStyle.textDecoration = "none";
      activeStyle.borderRadius = "15px";
      activeStyle.fontSize = "larger";
    } else {
      activeStyle.backgroundColor = primaryColor;
      activeStyle.color = "white";
      activeStyle.padding = "15px";
      activeStyle.textDecoration = "none";
      activeStyle.borderRadius = "15px";

      //   activeStyle.borderColor = yellowColor;
      //   activeStyle.borderSize = "5px";
      //   activeStyle.borderStyle = "solid";
    }
    if (typeof page === "string") {
      // activeStyle.backgroundColor = '#0D0D1C';
      activeStyle.backgroundColor = primaryColorWithAlpha;
      activeStyle.color = "black";
      activeStyle.textDecoration = "none";
      activeStyle.borderRadius = "15px";
      // activeStyle.border = '2px solid #0D0D1C';
      activeStyle.borderColor = primaryColor;
      activeStyle.borderSize = "5px";
      activeStyle.borderStyle = "solid";
    }
    return (
      <li className="page-item" key={page} style={{ padding: "5px" }}>
        <a href="#" onClick={handleClick} style={activeStyle}>
          {page}
        </a>
      </li>
    );
  };

  // size per page Style
  const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange,
  }) => (
    <div style={{ width: "100px" }}>
      <select
        value={currSizePerPage}
        onChange={(e) => onSizePerPageChange(e.target.value)}
        className="form-select select"
      >
        {options.map((option) => (
          <option key={option.text} value={option.page}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );

  // pagination options
  const paginationOptions = {
    pageButtonRenderer,
    sizePerPageRenderer,
  };

  return (
    <BootstrapTable
      bootstrap4
      keyField="id"
      data={data}
      columns={columns}
      bordered={false}
      noDataIndication={indication}
      pagination={paginationFactory(paginationOptions)}
    />
  );
};

export default Table;

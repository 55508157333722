import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Toast = (
  message,
  toastType,
  loading,
  closeTime = 2000,
  id = 100
) => {
  const isToastActive = toast.isActive(id);
  if (isToastActive) {
    toast.update(id, {
      render: message,
      type: toastType,
      isLoading: loading,
      autoClose: closeTime,
    });
  } else {
    toast[`${toastType}`](message, {
      toastId: id,
      autoClose: closeTime,
      position: toast.TOP_CENTER,
    });
  }
};
export const SuccessToast = (message) => {
  return toast.success(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

// Error toast component
export const ErrorToast = (message) => {
  return toast.error(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export default Toast;

import React from "react";

import styles from "../styles/ChatThreads.module.scss"

const SingleChatThread = ({
  handleThreadClick,
  image,
  name,
  lastMsg,
  isFile = false,
  lastTime,
  isOnline,
  unReadMsg,
}) => {

  return (
    <div >
      <div onClick={handleThreadClick} className="cursor-pointer">
        <div className={`my-2 overflow-visible ${styles['thread-card']}`}>
          <div className={styles.thread}>
            <div className={styles.imageDiv}>
              <img className={styles.image} src={image} alt="User Profile" />
              {isOnline ? <span className={styles.onlineIndicator}></span> : ""}
            </div>
            <div className={styles.userInfo}>
              <div className={styles.nameContainer}>
                <p className="fs-5 lc-1 m-0 pe-1 lc-1">{name}</p>
                <p className={`${styles.message} m-0 pe-1 lc-1`}>{(lastMsg ? lastMsg : isFile ? "Attachment" : "")}</p>
              </div>
              {unReadMsg === true ? <span className={styles.indicator}></span> : ""}
            </div>
          </div>
          <p className={`fs-8 ${styles['last-time']}`}>{lastTime}</p>
        </div>
      </div>
    </div>
  );
};

export default SingleChatThread;

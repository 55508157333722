import { createSlice } from "@reduxjs/toolkit";
import { promosApi } from "../services/promosApi";

const initialState = {
  promos: [],
};

export const promosSlice = createSlice({
  name: "promos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        promosApi.endpoints.getAllPromos.matchFulfilled,
        (state, { payload }) => {
          state.promos = payload.data;
        }
      )
      .addMatcher(promosApi.endpoints.getAllPromos.matchRejected, (state) => {
        state.promos = null;
      });
  },
});

export const { setUserInfo, unsetUserInfo, updateUsers } = promosSlice.actions;

export default promosSlice.reducer;

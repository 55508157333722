import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { FaCircleXmark } from "react-icons/fa6";
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import {
  useAddSubCategoryMutation,
  useUpdateSubCategoryMutation,
} from "../../Data/services/subCategoryApi";
import Toast, { ErrorToast } from "../../Hooks/useToast";
import { Button, CustomInput, Input } from "../CustomComponents";
import SelectFieldComponent from "../CustomComponents/SelectFieldComponent";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editCategoryModal.module.scss";

const EditSubCategoryModal = ({ handleEditModalClose, data, refetch }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      name: data ? data.name : "",
      mainCategory: data ? data.categoryId : "",
    },
  });

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [rowId, setRowId] = useState("");

  const [selectedStatus, setSelectedStatus] = useState("");

  // const dummyData = [
  //   {
  //     id: 1,
  //     name: "Men's Clothing",
  //     categoryId: 4,
  //     image:
  //       "http://buyatbundles.trangotechdevs.com:3015/uploads/1721039857682-72364421-male-3139289_1280.jpg",
  //     createdAt: "2024-07-15T10:37:50.000Z",
  //     updatedAt: "2024-07-15T10:37:50.000Z",
  //     deletedAt: null,
  //   },
  //   {
  //     id: 4,
  //     name: "Women's Clothing",
  //     categoryId: 4,
  //     image:
  //       "http://buyatbundles.trangotechdevs.com:3015/uploads/1721040065289-39135228-ai-generated-8846481_1280.jpg",
  //     createdAt: "2024-07-15T11:09:53.000Z",
  //     updatedAt: "2024-07-15T11:09:53.000Z",
  //     deletedAt: null,
  //   },
  //   {
  //     id: 5,
  //     name: "Children's Clothing",
  //     categoryId: 4,
  //     image:
  //       "http://buyatbundles.trangotechdevs.com:3015/uploads/1721040880253-478168596-kid-6620283_1280.jpg",
  //     createdAt: "2024-07-15T11:10:33.000Z",
  //     updatedAt: "2024-07-15T11:10:33.000Z",
  //     deletedAt: null,
  //   },
  //   {
  //     id: 6,
  //     name: "Accessories",
  //     categoryId: 4,
  //     image:
  //       "http://buyatbundles.trangotechdevs.com:3015/uploads/1721042653545-396494495-go-pro-1478810_1280.jpg",
  //     createdAt: "2024-07-15T11:24:36.000Z",
  //     updatedAt: "2024-07-15T11:24:36.000Z",
  //     deletedAt: null,
  //   },
  // ];

  var categoriesData = useSelector((state) => state.category.categories);
  // console.log(`userData = ${userData}`);

  // useEffect(() => {
  //   categoriesData = categoryData;
  // }, [categoryData]);

  const statusList = categoriesData.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const [mounted, setMounted] = React.useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (data) {
      setRowId(data.id);
      // console.log(data);
      categoriesData.filter((category) => {
        // console.log(category);
        if (data.categoryId === category.id) {
          setSelectedStatus(category.name);
          setValue("mainCategory", category.id);
        }
      });
    }
  }, [data, mounted]);

  const handleStatusChange = (value) => {
    console.log(value);
    setSelectedStatus(value);
  };
  // MARK: ADD SUB CATEGORY
  const [addSubCategory, { isError, isLoading, isSuccess }] =
    useAddSubCategoryMutation();

  const handleAddSubCategory = async (data) => {
    console.log("handleAddSubCategory");
    console.log(data);

    if (isSubmitted) return;

    try {
      setIsSubmitted(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("categoryId", data.mainCategory);

      if (data.image !== undefined) {
        formData.append("image", data.image[0]);
      }

      // Log the FormData entries to see what is being sent
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      const response = await addSubCategory(formData);
      console.log(response);

      if (response.data.success) {
        refetch();
        Toast(response.data.message, "success");
        handleEditModalClose();
      }

      setIsSubmitted(false);
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  // MARK: UPDATE SUB CATEGORY
  const [
    updateSubCategory,
    {
      isError: updateError,
      isLoading: updateLoading,
      isSuccess: updateSuccess,
    },
  ] = useUpdateSubCategoryMutation();

  const handleUpdateSubCategory = async (data) => {
    console.log("handleUpdateSubCategory");
    console.log(data);
    console.log(rowId);
    // console.log(data.image[0], "data.image");
    if (isSubmitted) return;
    try {
      setIsSubmitted(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("categoryId", data.mainCategory);

      if (data.image !== undefined) {
        formData.append("image", data.image[0]);
      }

      // Log the FormData entries to see what is being sent
      for (let [key, value] of formData.entries()) {
        console.log(`${key}:`, value);
      }

      const id = rowId;
      const response = await updateSubCategory({ formData, id });
      console.log(response);

      if (response.data.success) {
        refetch();
        Toast(response.data.message, "success");
        handleEditModalClose();
      }

      setIsSubmitted(false);
    } catch (error) {
      if (error?.data?.code === 400) {
        ErrorToast(error.data?.message);
      }
      setIsSubmitted(false);
    }
  };

  if (!mounted) {
    return;
  }

  return (
    <div>
      <ModalContainer handleClose={handleEditModalClose}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">
              {data ? "Edit Sub Category" : "Add Sub Category"}
            </p>
            <span className="bg-dark bg-opacity-75 d-flex p-3 rounded-5">
              <FaCircleXmark
                role="button"
                onClick={handleEditModalClose}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <Form
            onSubmit={handleSubmit(
              data ? handleUpdateSubCategory : handleAddSubCategory
            )}
          >
            <Row>
              <Col md={12}>
                <p className="m-0">Sub Category Name</p>
                <Controller
                  control={control}
                  name="name"
                  // defaultValue={""}
                  rules={{
                    required: "Sub Category Name is required.",
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Input
                      classes="mt-2 mb-3"
                      type="text"
                      // name="name"
                      placeholder="Enter category name"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                {errors?.name?.message ? (
                  <p className="m-0 text-danger text-center">
                    {errors?.name?.message}
                  </p>
                ) : (
                  ""
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <p className="m-0">Category Image</p>
                <Controller
                  control={control}
                  name="image"
                  // defaultValue={""}
                  // rules={{
                  //   required: "Image is required.",
                  // }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomInput
                      classes="mt-2 mb-3"
                      type="file"
                      // name="image"
                      // value={value}
                      onChange={(e) => {
                        console.log(e.target.files);
                        onChange(e.target.files);
                      }}
                    />
                  )}
                />
                {errors?.image?.message ? (
                  <p className="m-0 text-danger text-center">
                    {errors?.image?.message}
                  </p>
                ) : (
                  ""
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <p className="m-0">Sub-category of</p>
                <Controller
                  control={control}
                  name="mainCategory"
                  // defaultValue={""}
                  rules={{
                    required: "Select Main category.",
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <SelectFieldComponent
                      firstOption={
                        _.isEmpty(selectedStatus)
                          ? "Select Category"
                          : selectedStatus
                      }
                      optionsList={statusList}
                      value={value}
                      // onSelectChange={handleStatusChange}
                      onSelectChange={onChange}
                    />
                  )}
                />
                {errors?.mainCategory?.message ? (
                  <p className="m-0 text-danger text-center">
                    {errors?.mainCategory?.message}
                  </p>
                ) : (
                  ""
                )}
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Button
                  classes={`${cssStyles.cardBtn} mt-4 `}
                  text={
                    isSubmitted ? (
                      <BeatLoader size={10} />
                    ) : data ? (
                      "Update"
                    ) : (
                      "Save"
                    )
                  }
                  // onClick={handleSubmit}
                  type="submit"
                ></Button>
              </Col>
            </Row>
          </Form>
        </div>
      </ModalContainer>
    </div>
  );
};

export default EditSubCategoryModal;

import _ from "lodash";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

import styles from './styles/Inbox.module.scss';


import axios from "axios";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { FiSend } from "react-icons/fi";
import { ImAttachment } from "react-icons/im";
import { IoIosArrowBack, IoMdClose } from "react-icons/io";
import { IoDocumentOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { INITIAL_TOKEN, UPLOAD_URL } from "../../config/config";
import { INPUT_TYPES, MESSAGE_FILE_TYPE_ENUM, MESSAGE_FILE_TYPES, MESSAGE_IMAGE_TYPES, MESSAGE_MEDIA_TYPES, MESSAGE_TYPE_ENUM, MESSAGE_VIDEO_TYPES } from "../../constants";
import { useSocket } from "../../Context/SocketContext";
import { getMessageTime } from "../../Helper";
import { ErrorToast } from "../../Hooks/useToast";
import { useChatContext } from "./Context/ChatContext";

export default function Inbox({ isMobile = false }) {
  const socket = useSocket();
  const { isSocketConnected, user } = useSelector(state => state.auth)
  const { chatRoom, setChatRoom, updateLastMessagePayload, } = useChatContext()


  const INITIAL_CHAT_HISTORY = useMemo(() => {
    return {
      isError: false,
      isLoading: true,
      data: []
    }
  }, [])


  const messageRef = useRef();

  const [page, setPage] = useState(1);
  const [chatHistory, setChatHistory] = useState(INITIAL_CHAT_HISTORY);
  const [media, setMedia] = useState(undefined);


  useEffect(() => {
    if (isSocketConnected) {
      socket.on('_get_thread_messages', (data) => {

        setChatHistory(prev => {
          return {
            ...prev,
            isLoading: false,
            data: data?.data || []
          }
        })
      })
    }

    return () => {
      if (isSocketConnected) {
        socket.dispose('_get_thread_messages')
      }
    }

  }, [isSocketConnected])


  useEffect(() => {
    if (isSocketConnected) {
      socket.on('_send_message', (data) => {
        const message = data?.data

        console.log("Receive Message : ", chatRoom, message);

        if (message?.roomId !== chatRoom.room_id) return;
        if (message.senderId !== user.id) {
          setChatHistory(prev => {
            return {
              ...prev,
              data: [
                {
                  ...message,
                  sender: {
                    id: message.senderId,
                  },
                  receiver: {
                    id: message.receiverId
                  },
                },
                ...prev?.data
              ]
            }
          })
        }
        else {
          if (_.isUndefined(data?.payload?.message_uuid)) {
            setChatHistory(prev => {
              return {
                ...prev,
                data: [
                  {
                    ...message,
                    sender: {
                      id: message.senderId,
                    },
                    receiver: {
                      id: message.receiverId
                    },
                  },
                  ...prev?.data
                ]
              }
            })
          }
          else {
            setChatHistory(prev => {
              return {
                ...prev,
                data: [
                  {
                    ...message,
                    sender: {
                      id: message.senderId,
                    },
                    receiver: {
                      id: message.receiverId
                    },
                  },
                  ...prev?.data.filter(item => item.message_uuid !== data?.payload.message_uuid),
                ]
              }
            })
          }
        }
        updateLastMessagePayload(message)
        socket.emit('_resetMessageCount', { chat_room_slug: chatRoom.slug })
      })

    }

    return () => {
      if (isSocketConnected) {
        socket.dispose('_send_message')
      }
    }

  }, [isSocketConnected, chatRoom])


  useEffect(() => {
    if (!_.isEmpty(chatRoom) && (page > 1)) {
      socket.emit("get_thread_messages", { userId: chatRoom.id })
    }
  }, [page])


  useEffect(() => {
    if (!chatHistory.isLoading) {
      setChatHistory(INITIAL_CHAT_HISTORY)
    }

    if (!_.isEmpty(chatRoom)) {
      socket.emit("get_thread_messages", { userId: chatRoom.id })
    }
  }, [chatRoom])

  useEffect(() => {
    scrollChatContainer()
  }, [chatHistory?.data.length])





  const handleBackButtonClick = () => {
    setChatRoom({});
  };



  const scrollChatContainer = () => {
    const container = document.getElementById('chatContainer')
    container.scroll({ top: container.scrollHeight, behavior: 'smooth' });
  }


  const getFileType = () => {
    return MESSAGE_FILE_TYPES.includes(media.type) ? MESSAGE_FILE_TYPE_ENUM.PDF :
      MESSAGE_IMAGE_TYPES.includes(media.type) ? MESSAGE_FILE_TYPE_ENUM.IMAGE :
        MESSAGE_VIDEO_TYPES.includes(media.type) ? MESSAGE_FILE_TYPE_ENUM.VIDEO : ""
  }

  const handleSendMessage = async () => {
    const message = messageRef.current.value;
    if (!message && _.isUndefined(media)) return;

    try {
      const payload = {}

      if (!_.isUndefined(media)) {
        const formData = new FormData()
        formData.append('media', media);
        const res = await axios({
          method: "POST",
          url: UPLOAD_URL,
          data: formData,
          headers: {
            "Token": INITIAL_TOKEN,
            "Content-Type": "multipart/form-data"
          },
        })


        payload.type = MESSAGE_TYPE_ENUM.FILE;
        payload.attachmentType = getFileType();
        payload.file_name = media.name;
        payload.attachment = res?.data?.data?.url
      }
      else {
        payload.type = MESSAGE_TYPE_ENUM.TEXT;
      }
      // payload.chat_room_slug = chatRoom.slug;
      payload.message = message
      payload.message_uuid = uuidv4()
      payload.senderId = user.id;
      payload.role = "admin";
      payload.receiverId = chatRoom.id;

      setChatHistory(prev => {
        return {
          ...prev,
          data: [
            {
              ...payload,
              sender: {
                id: user.id,
              },
              receiver: {
                id: payload.receiverId
              },
              createdAt: new Date()
            },
            ...prev?.data,
          ]
        }
      })
      socket.emit('send_message', payload);
      messageRef.current.value = ''
      setMedia(undefined)
    }
    catch (err) {
      ErrorToast(err?.message);
    }
  }


  const handleMediaChange = (e) => {
    setMedia(e.target.files[0] || undefined)
  }


  const handleRemoveMedia = () => {
    setMedia(undefined)
  }

  const getPreviewMediaElement = () => {
    if (MESSAGE_FILE_TYPES.includes(media.type)) {
      return <>
        <span className={styles.documentIcon}><IoDocumentOutline /></span>
        <p className={`lc-1 m-0 mt-1 mb-2 ${styles.fileName}`}>{media?.name}</p>
      </>

    }
    else if (MESSAGE_IMAGE_TYPES.includes(media.type)) {
      return <img
        className={styles.image}
        src={URL.createObjectURL(media)}
        alt="Preview Image"
      />
    }
    else if (MESSAGE_VIDEO_TYPES.includes(media.type)) {
      return <video
        className={styles.video}
        controls={true}
        autoPlay={false}
      >
        <source src={URL.createObjectURL(media)} type="video/mp4" />
      </video>
    }
    else {
      return <p className={`lc-1 m-0 mt-1 mb-2 ${styles.fileName}`}>{"File type for supported"}</p>
    }
  }

  const getMessageMedia = (data) => {

    if (data?.attachmentType === MESSAGE_FILE_TYPE_ENUM.PDF) {
      return <div className={styles.file}>
        <span className={styles.documentIcon}><IoDocumentOutline /></span>
        <a className={`lc-1 m-0 mt-1 mb-2 ${styles.fileName}`} href={data?.attachment} target="_">{data.file_name}</a>
      </div>

    }
    else if (data?.attachmentType === MESSAGE_FILE_TYPE_ENUM.IMAGE) {
      return <img
        className={styles.image}
        src={data.attachment}
        alt="Preview Image"
      />
    }
    else if (data?.attachmentType === MESSAGE_FILE_TYPE_ENUM.VIDEO) {
      return <video
        className={styles.video}
        controls={true}
        autoPlay={false}
      >
        <source src={data.attachment} type="video/mp4" />
      </video>
    }
    else {
      return <></>
    }
  }

  return (

    <div className={styles.Inbox}>
      <div className={styles.titleContainer}>
        <div className={styles.userContainer}>
          <div className={styles.leftSide}>
            {isMobile ? (
              <span className={styles.backButton} onClick={handleBackButtonClick}>
                <IoIosArrowBack />
              </span>
            ) : (
              ""
            )}
            <div className={styles.imageDiv}>
              <img
                className={styles.image}
                src={chatRoom.image_url}
                alt="User Profile"
              />
              {/* <span className="onlineIndicator"></span> */}
            </div>

            <div className={styles.nameContainer}>
              <p className="lc-1 fs-4 mb-0">{chatRoom.room_name}</p>
              {/* <p className="onlineText">Online</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className={styles.chatContainer} id="chatContainer">


        {chatHistory?.isLoading ? <div className="w-100 h-100 d-flex align-items-center justify-content-center"><Spinner /></div> :
          _.isEmpty(chatHistory?.data) ? <div className="w-100 h-100 d-flex align-items-center justify-content-center"><p className="m-0 fs-5">{"No chat messages found"}</p></div> :
            (chatHistory?.data ?? []).map((item, index) => {
              const isMe = item.sender?.id == user?.id;
              return (
                <div className={`${styles.chat} ${isMe ? styles.me : ""}`} key={item.slug || item.message_uuid}>
                  <span className={styles.info}>{getMessageTime(item.createdAt)}</span>

                  {item.type !== MESSAGE_TYPE_ENUM.FILE ? "" :
                    <div className={`${styles.messageFile} ${isMe ? styles.me : ""}`}>
                      {getMessageMedia(item)}
                    </div>

                  }

                  {!item.message ? "" :
                    <p className={`${styles.message} ${isMe ? styles.me : ""}`}>
                      {item.message}
                    </p>
                  }
                  {/* {isMe ? <span className={styles.info}>{"Seen"}</span> : ""} */}
                </div>
              );
            })
        }
      </div>

      <div className={styles.messageContainer}>
        {_.isUndefined(media) ? "" :
          <div className={styles.documentContainer}>
            <span className={styles.closeIcon} onClick={handleRemoveMedia}><IoMdClose />  </span>
            <div className={styles.contentContainer}>
              {getPreviewMediaElement()}
            </div>
          </div>
        }

        <InputGroup className={styles.inputGroup}>
          <Form.Control
            size="lg"
            className={`mt-1 mb-2  ${styles.messageBar}`}
            placeholder={"Type message here..."}
            type={INPUT_TYPES.TEXT}
            ref={messageRef}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                handleSendMessage();
                e.stopPropagation()
              }
            }}
          // onChange={onChange}
          />
          {/* <div className={styles['input-before-icon']}>
            <HiOutlineEmojiHappy size={20} />
          </div> */}
          <div className={styles["input-after-icon"]}>
            <input type="file" id="document-btn" hidden className={styles.fileChooseInput} onChange={handleMediaChange} multiple={false} accept={MESSAGE_MEDIA_TYPES} key={uuidv4()} />
            <label htmlFor='document-btn' className={styles.label}>
              <ImAttachment size={20} />
            </label>
            {/* <ImAttachment size={20} /> */}
            {/* <TiCamera size={20} /> */}
          </div>
        </InputGroup>

        <button className={styles.sendButton} onClick={handleSendMessage}>
          <FiSend />
        </button>
      </div >
    </div >
  );
}

import React from "react";
import styles from "./styles/DashboardCard.module.css";
// import { CiUser } from "react-icons/ci";
import CountUp from "react-countup";

const DashboardCard = ({ bg1 = "", bg2 = "", icon, number, text }) => {
  return (
    <div
      className={`${styles.Card}`}
      style={{
        backgroundImage: `linear-gradient(0deg, ${bg1} 0%, ${bg2} 80%)`,
      }}
    >
      <div className={`${styles.iconCard}`}>{icon}</div>
      <div>
        <p className="fs-2 fw-bold m-0">
          <CountUp end={number} />
        </p>
        <p className="fs-5 m-0">{text}</p>
      </div>
    </div>
  );
};

export default DashboardCard;

import { createSlice } from "@reduxjs/toolkit";
import { productApi } from "../services/productApi";

const initialState = {
  products: [],
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        productApi.endpoints.getAllProducts.matchFulfilled,
        (state, { payload }) => {
          state.products = payload.data;
        }
      )
      .addMatcher(productApi.endpoints.getAllProducts.matchRejected, (state) => {
        state.products = null;
      });
  },
});

export const { setUserInfo, unsetUserInfo, updateUsers } = productSlice.actions;

export default productSlice.reducer;

import React from "react";

import styles from "./styles/ChatThreads.module.scss"

import _ from "lodash";
import { AiOutlineSearch } from "react-icons/ai";
import EmptyThreadContainer from "../../Components/Container/EmptyThreadContainer";
import { INPUT_TYPES, MESSAGE_TYPE_ENUM } from "../../constants";
import { getTime } from "../../Helper";
import SingleChatThread from "./Components/SingleChatThread";
import { useChatContext } from "./Context/ChatContext";
import { useSelector } from "react-redux";

export default function ChatThreads() {
  const { user } = useSelector(state => state.auth);
  const { chatRoom, setChatRoom, search, setSearch, chatThread, resetMessageCount } = useChatContext()

  const handleThreadClick = (room_id, user) => {
    if (user?.id === chatRoom?.id) return;
    setChatRoom({
      id: user.id,
      room_id: room_id,
      slug: user.chat_room_slug,
      room_name: user.displayName || '',
      image_url: user.profileImage
    });
    resetMessageCount(room_id)
  };



  return (
    <div className={styles.ChatThreads}>
      <div className={styles.titleContainer}>
        <p className="m-0 fs-4">{"Messages"}</p>
      </div>
      <div className={styles.threads}>
        <div className={`${styles.search} mb-1`}>
          <div className={styles.searchButton}>
            <AiOutlineSearch />
          </div>
          <input
            className="p-2 ms-1"
            type={INPUT_TYPES.TEXT}
            placeholder={"Search"}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        {
          (chatThread?.isLoading || _.isEmpty(chatThread?.data)) ? <EmptyThreadContainer isLoading={chatThread?.isLoading} /> :
            (chatThread?.data ?? []).map((item) => {
              const room_user = item.sender.id === user.id ? item.receiver : item.sender;
              const message = item.chat_messages?.length ? item.chat_messages[0] : {}

              return <SingleChatThread
                key={item.slug}
                handleThreadClick={() => handleThreadClick(item.roomId, room_user)}
                image={room_user.profileImage}
                name={room_user.displayName}
                lastMsg={message?.message || ''}
                isFile={message?.type === MESSAGE_TYPE_ENUM.FILE}
                lastTime={message?.createdAt ? getTime(message?.createdAt) : ""}
                isOnline={false}
                unReadMsg={false}
              />
            }
            )
        }
      </div>
    </div>
  );
}

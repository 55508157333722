import { createSlice } from "@reduxjs/toolkit";
import { festiveProductApi } from "../services/festiveProductApi";

const initialState = {
  festiveProducts: [],
};

export const festiveProductSlice = createSlice({
  name: "festive-products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        festiveProductApi.endpoints.getAllFestiveProducts.matchFulfilled,
        (state, { payload }) => {
          state.festiveProducts = payload.data;
        }
      )
      .addMatcher(
        festiveProductApi.endpoints.getAllFestiveProducts.matchRejected,
        (state) => {
          state.festiveProducts = null;
        }
      );
  },
});

export const { setUserInfo, unsetUserInfo, updateUsers } =
  festiveProductSlice.actions;

export default festiveProductSlice.reducer;

import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";

//
import { IoEyeSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import { ShimmerTable } from "react-shimmer-effects";
import ViewOrderModal from "../../Components/Modals/ViewOrderModal";
import { useGetAllOrdersQuery } from "../../Data/services/orderApi";

const OrderManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { data, isSuccess, isLoading, isFetching, refetch } =
    useGetAllOrdersQuery({ refetchOnMountOrArgChange: true });

  var orderData = useSelector((state) => state.orders.orders);
  // console.log(`orderData = ${orderData}`);

  useEffect(() => {
    if (orderData.length === 0) {
      setIndicationMessage("No order found.");
    }

    setRowData(orderData);
  }, [orderData]);

  const handleDeleteModalOpen = () => {
    setDeleteModal(true);
  };

  const handleEditModalOpen = () => {
    setEditModal(true);
  };

  const handleEditModalClose = () => {
    setEditModal(false);
    setEditedData("");
  };

  const handleAddUser = () => {
    setEditModal(true);
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    return <ShimmerTable row={5} col={8} />;
  }

  const columns = [
    {
      dataField: "id",
      text: "Order #",
      sort: true,
      headerStyle: {
        width: "80px",
      },
    },
    // {
    //   dataField: "products",
    //   text: "No. of Products",
    //   sort: true,
    //   align: "center",
    //   headerStyle: {
    //     width: "80px",
    //   },
    //   formatter: (cellContent, row) => {
    //     if (row.id) {
    //       return row.orderProducts.length;
    //     }
    //   },
    // },
    {
      dataField: "totalItems",
      text: "No. of Items",
      sort: true,
      align: "center",
      headerStyle: {
        width: "80px",
      },
      formatter: (cellContent, row) => {
        if (row.id) {
          return row.orderProducts.length;
        }
      },
    },
    {
      dataField: "deliveryFee",
      text: "Delivery Fee",
      sort: true,
      align: "center",
      headerStyle: {
        width: "80px",
      },
    },
    {
      dataField: "totalPayment",
      text: "Total Amount",
      sort: true,
      align: "center",
      headerStyle: {
        width: "80px",
      },
    },
    {
      dataField: "isPaid",
      text: "Payment Status",
      sort: true,
      align: "center",
      headerStyle: {
        width: "80px",
      },
      formatter: (cellContent, row) => {
        if (row.id) {
          if (row.isPaid === 1)
            return <div className={styles.delivered}> Paid </div>;
          if (row.isPaid === 0)
            return <div className={styles.pending}> UnPaid </div>;
        }
      },
    },

    {
      dataField: "user.displayName",
      text: "User",
      sort: true,
      align: "center",
      headerStyle: {
        width: "100px",
      },
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "100px",
      },
      formatter: (cellContent, row) => {
        if (row.status === "delivered")
          return <div className={styles.delivered}> Delivered </div>;
        if (row.status === "pending")
          return <div className={styles.pending}>pending</div>;
      },
    },
    {
      dataField: "actions",
      text: "Actions",
      headerAlign: "center",
      align: "center",
      headerStyle: {
        width: "200px",
      },
      formatter: (cellContent, row) => {
        // console.log(row)
        if (row.id) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div
                className={`${styles.btnBg} d-flex justify-content-center align-items-center text-white rounded-3 mx-2 p-2 `}
                role="button"
                onClick={() => {
                  setEditedData(row);
                  handleEditModalOpen(true);
                }}
              >
                <span
                  className={`align-items-center d-flex justify-content-center pe-1 `}
                >
                  <IoEyeSharp />
                </span>
                <span>View</span>
              </div>
              {
                // <div
                //   className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
                //   role="button"
                //   onClick={() => {
                //     setEditedData(row);
                //     handleDeleteModalOpen();
                //   }}
                // >
                //   <span className={`d-flex align-items-center pe-1`}>
                //     <MdDeleteForever />
                //   </span>
                //   <span>Delete</span>
                // </div>
              }
            </div>
          );
        }
      },
    },
  ];

  return (
    <div className={styles.MainContainer}>
      {
        //  deleteModal ? (
        //   <DeleteModal
        //     handleDeleteModalClose={handleDeleteModalClose}
        //     data={editedData}
        //     onDelete={deleteData}
        //   />
        // ) : (
        //   ""
        // )
      }

      {editModal ? (
        <ViewOrderModal
          handleEditModalClose={handleEditModalClose}
          data={editedData}
          refetch={refetch}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Order Management"} classes="black-text" />
        </div>
        {
          // <Row>
          //   <Col md={12}>
          //     <Button
          //       classes={`${styles.cardBtn} `}
          //       text="Add User"
          //       onClick={handleAddUser}
          //     ></Button>
          //   </Col>
          // </Row>
        }
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData ?? []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default OrderManagementScreen;

import React, { useEffect, useState } from "react";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";
import { SubTitle } from "../../Components/CustomComponents";
import Table from "../../Components/Table/Table";
import styles from "./styles/user_management.module.scss";
// import { ToastContainer, toast } from "react-toastify";
import { ShimmerTable } from "react-shimmer-effects";
//
import { useDispatch, useSelector } from "react-redux"; // for getting and setting data into local redux
import WithDrawModal from "../../Components/Modals/WithDrawModal";
import {
  useConnectAccountMutation,
  useGetTransactionQuery,
} from "../../Data/services/paymentApi";
import { useGetMyProfileQuery } from "../../Data/services/userAuthApi";
import Toast from "../../Hooks/useToast";
import ConnectAccount from "./ConnectAccount";
import WithDrawalAmount from "./WithDrawalAmount";

const WithdrawManagementScreen = ({ setPage }) => {
  const dispatch = useDispatch();

  // const [data, setData] = useState([]);
  const [indicationMessage, setIndicationMessage] = useState("loading...");
  const [rowData, setRowData] = useState([]);
  const [accountBalance, setAccountBalance] = useState("");
  const [editedData, setEditedData] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);

  const {
    data: profileData,
    isSuccess,
    isLoading,
    isFetching,
    refetch: reFetchProfile,
  } = useGetMyProfileQuery({ refetchOnMountOrArgChange: true });

  // console.log(profileData);

  const {
    data: transactionData,
    isSuccess: txnSuccess,
    isLoading: txnLoading,
    isFetching: txnFetching,
    refetch: reFetchTxn,
  } = useGetTransactionQuery({ refetchOnMountOrArgChange: true });

  // console.log(transactionData);

  useEffect(() => {
    if (transactionData) {
      setRowData(transactionData?.data?.transactions);
      setAccountBalance(transactionData?.data?.accountBalance);
    }
  }, [transactionData]);

  var vendorProfile = useSelector((state) => state.auth.user);
  // console.log(vendorProfile);

  const refetchBoth = () => {
    reFetchProfile();
    reFetchTxn();
  };

  const [
    connectAccount,
    {
      isError: connectAccountError,
      isLoading: connectAccountLoading,
      isSuccess: connectAccountSuccess,
    },
  ] = useConnectAccountMutation();

  const handleConnectAccount = async () => {
    console.log("handleConnectAccount");
    try {
      const response = await connectAccount();
      console.log(response);
      if (response.data.status === 200) {
        const url = response.data.data.account_link.url;

        const newWindow = window.open(url, "_blank");
      }
    } catch (error) {
      console.log(error);
      Toast(error.message, "error");
    }
  };

  const handleWithdrawModalOpen = () => {
    setWithdrawModal(true);
  };

  const handleWithdrawModalClose = () => {
    setWithdrawModal(false);
    setEditedData("");
  };

  function indication() {
    // return something here
    // return 'Table is empty';
    isLoading ? (
      <ShimmerTable row={5} col={8} />
    ) : (
      setIndicationMessage("No data found")
    );

    return indicationMessage;
  }

  const columns = [
    {
      dataField: "gateway_transaction_id",
      text: "Txn #",
      sort: true,
      headerStyle: {
        width: "150px",
        minWidth: "100px",
      },
      style: { 
        whiteSpace: "normal",  // Allow wrapping
        wordBreak: "break-word" // Break long words
      },
      formatter: (cellContent, row) => {
        return (
          <span >
            {row.gateway_transaction_id ?? "Null"}
          </span>
        );
      },
    },
    // {
    //   dataField: "transaction_amount",
    //   text: "Txn Amount",
    //   sort: true,
    //   align: "center",
    //   headerStyle: {
    //     width: "80px",
    //   },
    // },
    {
      dataField: "transaction_type",
      text: "Txn Type",
      sort: true,
      align: "center",
      headerStyle: {
        width: "80px",
      },
      formatter: (cellContent, row) => {
        if (row.id) {
          if (row.transaction_type === "debit") {
            return <div className={styles.debit}> Debit </div>;
          } else return <div className={styles.credit}> Credit </div>;
        }
      },
    },
    {
      dataField: "previous_amount",
      text: "Previous Balance",
      sort: true,
      align: "center",
      headerStyle: {
        width: "80px",
      },
    },
    {
      dataField: "transaction_amount",
      text: "Txn Amount",
      sort: true,
      align: "center",
      headerStyle: {
        width: "80px",
      },
    },
    {
      dataField: "new_amount",
      text: "New Balance",
      sort: true,
      align: "center",
      headerStyle: {
        width: "80px",
      },
    },

    // {
    //   dataField: "user.displayName",
    //   text: "User",
    //   sort: true,
    //   align: "center",
    //   headerStyle: {
    //     width: "100px",
    //   },
    // },
    // {
    //   dataField: "status",
    //   text: "Status",
    //   sort: true,
    //   headerAlign: "center",
    //   align: "center",
    //   headerStyle: {
    //     width: "100px",
    //   },
    //   formatter: (cellContent, row) => {
    //     if (row.status === "delivered")
    //       return <div className={styles.delivered}> Delivered </div>;
    //     if (row.status === "pending")
    //       return <div className={styles.pending}>pending</div>;
    //   },
    // },
    // {
    //   dataField: "actions",
    //   text: "Actions",
    //   headerAlign: "center",
    //   align: "center",
    //   headerStyle: {
    //     width: "200px",
    //   },
    //   formatter: (cellContent, row) => {
    //     // console.log(row)
    //     if (row.id) {
    //       return (
    //         <div className="d-flex justify-content-center align-items-center ">
    //           <div
    //             className={` d-flex justify-content-center align-items-center bg-primary text-white rounded-3 mx-2 p-2 `}
    //             role="button"
    //             onClick={() => {
    //               setEditedData(row);
    //               handleWithdrawModalOpen(true);
    //             }}
    //           >
    //             <span
    //               className={`align-items-center d-flex justify-content-center pe-1 `}
    //             >
    //               <CiDeliveryTruck />
    //             </span>
    //             {<span>Shi...</span>}
    //           </div>

    //           <div
    //             className={` d-flex justify-content-center align-items-center text-white rounded-3 mx-2 p-2  ${
    //               row.fedexInvoice === "" ? "bg-dark-subtle" : "bg-primary"
    //             }`}
    //             role={row.fedexInvoice === "" ? "" : "button"}
    //             onClick={() => {
    //               if (row.fedexInvoice === "") {
    //                 return;
    //               } else {
    //                 window.open(row.fedexInvoice, "_blank");
    //               }
    //             }}
    //           >
    //             <span
    //               className={`align-items-center d-flex justify-content-center pe-1 `}
    //             >
    //               <GrDocumentPdf />
    //             </span>
    //             {<span>Dow...</span>}
    //           </div>

    //           <div
    //             className={`${styles.btnBg} d-flex justify-content-center align-items-center text-white rounded-3 mx-2 p-2 `}
    //             role="button"
    //             onClick={() => {
    //               setEditedData(row);
    //               handleEditModalOpen(true);
    //             }}
    //           >
    //             <span
    //               className={`align-items-center d-flex justify-content-center pe-1 `}
    //             >
    //               <IoEyeSharp />
    //             </span>
    //             <span>View</span>
    //           </div>

    //           {
    //             // <div
    //             //   className="d-flex justify-content-center align-items-center bg-danger text-white rounded-3 p-2 mx-2 "
    //             //   role="button"
    //             //   onClick={() => {
    //             //     setEditedData(row);
    //             //     handleDeleteModalOpen();
    //             //   }}
    //             // >
    //             //   <span className={`d-flex align-items-center pe-1`}>
    //             //     <MdDeleteForever />
    //             //   </span>
    //             //   <span>Delete</span>
    //             // </div>
    //           }
    //         </div>
    //       );
    //     }
    //   },
    // },
  ];
  //  created = green | debit = red
  return (
    <div className={styles.MainContainer}>
      {withdrawModal ? (
        <WithDrawModal
          handleEditModalClose={handleWithdrawModalClose}
          // rowData={editedData}
          currentBalance={vendorProfile.accountBalance}
          refetch={refetchBoth}
        />
      ) : (
        ""
      )}

      <div className={styles.headerContainer}>
        <div>
          <SubTitle text={"Withdraw Management"} classes="black-text" />
        </div>
      </div>

      <div className={`${styles.withDrawContainer}`}>
        <WithDrawalAmount
          amount={vendorProfile.accountBalance}
          styles={styles}
          onClick={
            vendorProfile.merchantId !== null 
              ? handleWithdrawModalOpen
              : handleConnectAccount
          }
        />
        <ConnectAccount
          styles={styles}
          onClick={
            vendorProfile.merchantId !== null ? null : handleConnectAccount
          }
          loading={connectAccountLoading}
          merchantId={vendorProfile.merchantId}
        />
      </div>

      <div className={styles.mainTable}>
        {
          <Table
            data={rowData || []}
            columns={columns}
            indication={indication}
          />
        }
      </div>
    </div>
  );
};

export default WithdrawManagementScreen;

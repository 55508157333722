import React, { useState } from "react";
import { useGetAllCategoriesQuery } from "../../Data/services/categoryApi";
import { useGetAllSubCategoriesQuery } from "../../Data/services/subCategoryApi";
import { ROUTES } from "./constants";
import Dashboard from "./Dashboard";

export default function Index() {
  const [page, setPage] = useState(ROUTES.DETAILS);

  useGetAllCategoriesQuery({ refetchOnMountOrArgChange: true });

  useGetAllSubCategoriesQuery({ refetchOnMountOrArgChange: true });

  switch (page) {
    case ROUTES.DETAILS:
      return <Dashboard setPage={setPage} />;

    default:
      return <Dashboard setPage={setPage} />;
  }
}

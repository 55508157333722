import _ from "lodash";
import React from "react";

import styles from "./styles/Layout.module.scss"

import { Col, Row } from "react-bootstrap";
import EmptyContainer from "../../Components/Container/EmptyContainer";
import ChatThreads from "./ChatThreads";
import { useChatContext } from "./Context/ChatContext";
import Inbox from "./Inbox";

export default function DesktopLayout() {
  const { chatRoom } = useChatContext()

  return (
    <div className={styles.Layout}>
      <Row className={styles.row}>
        <Col className={styles.col} md={4}>
          <ChatThreads />
        </Col>
        <Col className={styles.col} md={8}>
          {!_.isEmpty(chatRoom) ?
            <Inbox key={chatRoom?.slug} />
            :
            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
              <EmptyContainer text={"Select chat to load messages"} classes="m-0" />
            </div>
          }
        </Col>
      </Row>
    </div>
  );
}

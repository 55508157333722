// export const BASE_URL_V1 = "https://api.primetime-tournaments.com/" // todo:
export const BASE_URL_V2 = "https://dummyjson.com";

export const WEB_ROUTES = {
  ACCOUNT: "account",
  DASHBOARD: "dashboard",
  USER_MANAGEMENT: "user-management",
  CATEGORY_MANAGEMENT: "category-management",
  SUB_CATEGORY_MANAGEMENT: "sub-category-management",
  PRODUCT_MANAGEMENT: "product-management",
  ORDER_MANAGEMENT: "order-management",
  WITHDRAWAL_MANAGEMENT: "withdrawal-management",

  PROMOS_MANAGEMENT: "promos-management",
  BLOGS_MANAGEMENT: "blogs-management",
  ALERT_MANAGEMENT: "alert-management",

  PROMO_CODE_MANAGEMENT: "promo-code-management",
  FESTIVE_CATEGORY_MANAGEMENT: "festive-category",
  FESTIVE_PRODUCT_MANAGEMENT: "festive-products",
  MEMBERSHIP_VIEW: "membership-view",

  SEND_NOTIFICATION: "send-notification",
  SETTING: "settings",
  SUPPORT: "support"
};

export const LOCAL_STORAGE = {
  setUser: "PTTUSER",
  setToken: "PTTTOKEN",
};

export const BREAKPOINTS = {
  MOBILE: 991,
};

export const apiUrl = {
  auth: "auth",
  api: "api",
  role: "admin",
  user: "user",
  login: "login",
  forgotPass: "forgot-password",
  changePass: "change-password",

  // tournament
  tournament: "tournament",
  livetournament: "tournament/live",
  scheduletournament: "tournament/schedule",
  ageGroup: "age-group",
  refund: "refund",
  startmatch: "match/start",
  endmatch: "match/end",
};

// DATA ->
// DUMMY
export const sportsStateList = [
  { value: 1, label: "State 1" },
  { value: 2, label: "State 2" },
  { value: 3, label: "State 3" },
];

export const sportsTypeList = [{ value: "Baseball", label: "Baseball" }];

export const formateTTime = (dateStr) => {
  const date = new Date(dateStr);
  const options = { hour: "numeric", minute: "2-digit", hour12: true };
  const timeFormatted = date.toLocaleString("en-US", options);
  return timeFormatted;
};

export const formatedDate = (dateStr) => {
  const date = new Date(dateStr);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    // hour: 'numeric',
    // minute: '2-digit',
    // hour12: true
  };
  const dateFormatted = date.toLocaleString("en-US", options);
  return dateFormatted;
};



export const IMAGE_TYPES = '*.png,*jpeg,*.jpg,image/png,image/jpeg,image/jpg'
export const VIDEO_TYPES = '*.mp4,video/mp4,*.mov,video/mov'


export const MESSAGE_IMAGE_TYPES = '*.png,*jpeg,*.jpg,image/png,image/jpeg,image/jpg'
export const MESSAGE_VIDEO_TYPES = '*.mp4,video/mp4,*.mov,video/mov'
export const MESSAGE_FILE_TYPES = 'application/pdf,*.pdf'

export const MESSAGE_MEDIA_TYPES = `${MESSAGE_IMAGE_TYPES},${MESSAGE_VIDEO_TYPES}`

export const MESSAGE_TYPE_ENUM = {
  TEXT: "text",
  FILE: "file",
}

export const MESSAGE_FILE_TYPE_ENUM = {
  IMAGE: "image",
  PDF: "pdf",
  VIDEO: "video"
}



//MARK: INPUT TYPES
export const INPUT_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  TEXTAREA: 'textarea',
  EMAIL: 'email',
  PASSWORD: 'password',
  FULL_NAME: 'full_name',
  TIME: 'time',
  SUBMIT: 'submit',
}
import React from "react";

import styles from "./styles/Dashboard.module.scss";

import { Col, Row } from "react-bootstrap";
import { SubTitle } from "../../Components/CustomComponents";
// import MatchCard from '../../Components/Cards/MatchCard';
// import TournamentCard from '../../Components/Cards/TournamentCard';
import { FaDatabase } from "react-icons/fa6";
import { useSelector } from "react-redux";
import DashboardCard from "../../Components/Cards/DashboardCard";

export default function Dashboard({ setPage }) {
  var dashboardData = useSelector((state) => state.dashboard.dashboardData);

  return (
    <div className={styles.Dashboard}>
      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle text={"Dashboard"} classes="black-text" />
        </div>
      </div>

      <Row className={`${styles.matchRow} w-100 mt-2 mb-5`}>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#00BFFF"}
            bg2={"#87CEFA"}
            icon={<FaDatabase color="#00BFFF" />}
            number={dashboardData == null ? 0 : dashboardData.vendors}
            text={"Numbers of Vendors"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#008080"}
            bg2={"#00FFFF"}
            icon={<FaDatabase color="#008080" />}
            number={dashboardData == null ? 0 : dashboardData.users}
            text={"Numbers of Users"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg2={"#FF6347"}
            bg1={"#F3865F"}
            icon={<FaDatabase color="#FF6347" />}
            number={dashboardData == null ? 0 : dashboardData.products}
            text={"Numbers of Products"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#FF8C00"}
            bg2={"#FFA500"}
            icon={<FaDatabase color="#FF8C00" />}
            number={dashboardData == null ? 0 : dashboardData.orders}
            text={"Numbers of Orders"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#32CD32"}
            bg2={"#90EE90"}
            icon={<FaDatabase color="#32CD32" />}
            number={dashboardData == null ? 0 : dashboardData.ordersPending}
            text={"Numbers of Pending Orders"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#9932CC"}
            bg2={"#9370DB"}
            icon={<FaDatabase color="#9932CC" />}
            number={dashboardData == null ? 0 : dashboardData.orderDelivered}
            text={"Numbers of Cancel Orders"}
          />
        </Col>
      </Row>
    </div>
  );
}
